import DocumentIcon from "src/components/documenticon/DocumentIcon";
import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import CmsImage from 'src/components/images/CmsImage';
import CSSAdContentLabel, { CSSAdContentLabelAfter } from "src/components/styles/CSSAdContentLabel";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';
import IFilmnytt, { isFilmnytt } from 'src/lib/types/Filmnytt';
import { IFilmomtale } from 'src/lib/types/Filmomtale';
import { ThemeValues } from "src/lib/types/IForsidenV2";
import { getImage, getUrlForDoctype } from 'src/lib/types/sanity';
import { Slug, SanityDocument } from 'sanity';

//#region [Props]
export type ImageTitleProps = {
    document: IFilmnytt | IFilmomtale;
    useSectionTitle?: boolean;
    className?: string;
    themeValue?: ThemeValues;
    isContentAd?: boolean;
    isStreamingContext?: boolean;
    sizes: string;
    forcedAspect?: number;
    headerLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    contentAdLabelPlacementMobile?: "overlay" | "bottom"
};
//#endregion

//#region [Component]
export default function ImageTitle({ document, className, sizes, themeValue, isContentAd = false, isStreamingContext = false, forcedAspect, useSectionTitle = false, headerLevel = 1, contentAdLabelPlacementMobile = "overlay" }: ImageTitleProps) {
    const slug = isFilmnytt(document) ? document.slug.current : document.mainVersionEDI;
    const url = getUrlForDoctype(document._type, slug, document.isStreaming as boolean | undefined || isStreamingContext, document);
    const image = getImage(document);
    const title = useSectionTitle ? (document.sectionPageTitle as string ?? document.title) : document.title;

    const Container = isContentAd ? SImageTitleContentAd : SImageTitle;

    return <Container $themeValue={themeValue} $adLabelPlacementMobile={contentAdLabelPlacementMobile} className={className}>
        <SFilmwebLink to={url!} $themeValue={themeValue} $isContentAd={isContentAd} $adLabelPlacementMobile={contentAdLabelPlacementMobile}>
            {image && <SCmsImage $aspect={forcedAspect} image={image} alt={image.asset?.altText ?? ""} sizes={sizes} width={640} nonPosterAspect={forcedAspect} crop />}

            <SHeader $themeValue={themeValue} $isContentAd={isContentAd}>
                <SH as={headerLevel !== 1 ? `h${headerLevel}` : undefined}>{title}</SH>
                <DocumentIcon document={document} />
            </SHeader>
        </SFilmwebLink>
    </Container>;
}
//#endregion

//#region [Styles]
type SImageTitleProps = {
    $themeValue?: ThemeValues;
}

type SImageTitlePropsWithAdLabelPlacement = {
    $adLabelPlacementMobile: "overlay" | "bottom"
    $isContentAd?: boolean;
} & SImageTitleProps;

function getThemeValueBg(th?: ThemeValues): string {
    switch (th) {
        case "light":
            return "var(--TEXTCOLOR_OVERRIDE, var(--textcolor))";
        case "lightGradient":
            return theme.palette.gradient1;
        case "gold":
            return theme.palette.gullHovedfarge;
        default:
            return "var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor))";
    }
}

const SImageTitle = styled.article<SImageTitlePropsWithAdLabelPlacement>`
	overflow: hidden;
	border-radius: 10px;
	position: relative;
    background: ${props => getThemeValueBg(props.$themeValue)};
    color: ${props => getThemeValueColor(props.$themeValue)};
`;

const SImageTitleContentAd = styled(SImageTitle)`
    color: #8fddfd;

    ${props => (props.$themeValue ?? "dark") !== "dark" ? css`
        color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
    `: ""}

    ${CSSAdContentLabel}

    ${props => (props.$themeValue ?? "dark") !== "dark" ? css`
        &::before {
            color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
            background: #8fddfd;
        }
    `: ""}

    ${props => props.$adLabelPlacementMobile === "bottom" ? css`
        @media ${theme.mq.mobile} {

            &::before {
                display: none;
                content: none;
            }

            ${CSSAdContentLabelAfter}
        }
    `: ""}
`;

function getThemeValueColor(th?: ThemeValues): string {
    switch (th) {
        case "gold":
        case "light":
        case "lightGradient":
            return "var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor))";
        default:
            return "var(--TEXTCOLOR_OVERRIDE, var(--textcolor))";
    }
}

const SFilmwebLink = styled(FilmwebLink) <SImageTitlePropsWithAdLabelPlacement>`
	text-decoration: none;
	height: 100%;
	z-index: 0;
	display: flex;
	flex-direction: column;
    color: currentcolor;

    &:hover {
		text-decoration: underline;
	}

    ${props => props.$adLabelPlacementMobile === "bottom" ? css`
        @media ${theme.mq.mobile} {
            height: auto;
        }
    `: ""}
`;

const SHeader = styled.header<SImageTitleProps & { $isContentAd?: boolean; }>`
	position: relative;
	padding: min(20px, 3.75%);
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;

	${props => props.$isContentAd && (props.$themeValue ?? "dark") !== "dark" ? css`
		background-color: #8fddfd;
	`: ""}
`;

const SH = styled.h1`
	order: 2;
	font-weight: bold;
	font-size: 22px;
	overflow: hidden;
`;

type SCmsImageProps = {
    $aspect?: number;
}

const SCmsImage = styled(CmsImage) <SCmsImageProps>`
	object-fit: contain;
	${props => props.$aspect ? css`
		aspect-ratio: ${props.$aspect};
	`: ""}

`;


//#endregion
